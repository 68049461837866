.breadcrumb-chevron {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236c757d'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    gap: .5rem;
  }
  .breadcrumb-chevron .breadcrumb-item {
    display: flex;
    gap: inherit;
    align-items: center;
    padding-left: 0;
    line-height: 1;
  }
  .breadcrumb-chevron .breadcrumb-item::before {
    gap: inherit;
    float: none;
    width: 1rem;
    height: 1rem;
  }
  