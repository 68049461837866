.cus-logo {
    width: 120px;
}

.cus-text-menu {
    font-size: 18px;
    color: black;
    font-weight:bold ;
}

.cus-text-menu:hover {
    color: red;
}

.cus-text-menu .dropdown-toggle {
    color: black;
}

.cus-text-menu .dropdown-toggle:hover {
    color: red;
}

.cus-text-menu.active-drop .dropdown-toggle {
    color: red !important;
}

.cus-text-menu.show .dropdown-menu {
    background-image: linear-gradient(to top left, #FFB600, #FFB600);
}

.dropdown-item:hover {
    background-image: linear-gradient(to top left, rgb(180, 180, 180),  rgb(180, 180, 180));
}

.dropdown-item.active .cus-text-menu {
    color:red !important;
}

.cus-menu-bg-color {
    background-image: linear-gradient(to top left, #FFB600, #FFB600);
}

.navbar .nav-link.active .cus-text-menu {
    color:red;
    background-image: linear-gradient(to top left, #FFB600, #FFB600);
}