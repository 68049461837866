.cus-icon-footer {
    font-size: 2rem;
}

.cus-footer-link:hover {
    color: yellow;
}

.cus-footer-bg-color{
    background-color: rgb(0, 53, 61);
}