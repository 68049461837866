.cus-image-container {
    position: relative;
    display: inline-block;
    width: 100%;
}
  
.cus-contact-image {
    display: block;
    width: 100%;
    height: auto;
}
  
.cus-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 100%;
    padding: 0 30px 0 30px;
}

.white-border-bottom {
    border-bottom: 3px solid #ffffff;
    width: 80px;
}

.color-border-bottom {
    border-bottom: 3px solid rgb(0, 0, 114);
    width: 100px;
}

.color-text {
    color: rgb(0, 0, 114);
}